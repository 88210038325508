<template>
	<div>
		<div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../../assets/image/eventname2.png" alt="">
					<p>赛事名称</p>
				</div>
				<el-input placeholder="输入赛事名称" v-model="info.matchName" style="width: 180PX;"></el-input>
			</div>
			<div class="xian2"></div>
			<div class="nian">
				<div style="display: flex;align-items: center;">
					<img src="../../../assets/image/runproject.png" alt="">
					<p>审核状态</p>
				</div>
				<div class="select">
					<a-select option-label-prop="label" placeholder="请选择" v-model="info.status" allowClear>
						<a-select-option v-for="item in options1" :key="item.itemValue" :value="item.itemValue"
							:label="item.itemText">
							<a-icon type="check" v-if="item.itemValue === info.status" />
							{{ item.itemText }}
						</a-select-option>
					</a-select>
				</div>
			</div>
			<div class="xian2"></div>
			<div class="buts" @click="searchfor">
				搜索
			</div>
		</div>
		<div class="zx-box" v-if="Object.keys(tableData).length === 0">
			<img class="zw" src="../../../assets/image/333.png" alt="">
			<div class="zwxx">暂无报名记录</div>
		</div>
		<div v-else>
			<div class="box" v-for="(item, index) in tableData" :key="index">
				<div class="imgbox">
					<div class="img">
						<img :src="item.image" alt="">
					</div>
					<div class="status" v-if="item.status == 0">
						{{ item.status_dictText }}
					</div>
					<div class="status" v-if="item.status == 1" style="background: #FF975F;">
						{{ item.status_dictText }}
					</div>
					<div class="status" v-if="item.status == 2" style="background: #61AB2A;">
						{{ item.status_dictText }}
					</div>
					<div class="status" v-if="item.status == 3" style="background: #C52818;">
						{{ item.status_dictText }}
					</div>
				</div>
				<div class="information">
					<div class="title">{{ item.matchName }}</div>
					<div class="name">报名人：{{ item.enrollName }} </div>
					<div class="name">
						<div class="nowrap" @click="getcertificate(item.enrollProject, '报名项目')" style="color: #2A7AFF;">
							<span>报名项目：</span>{{ item.enrollProject }}
						</div>
					</div>
					<div class="name" v-if="item.status == 0" @click="newlyadded(item, 1)">
						<span> 提交时间：{{ formatTime(item.submitDate) }}</span>
						<span class="but">编辑</span>
					</div>
					<div class="name" v-if="item.status == 1 || item.status == 2" @click="newlyadded(item, 2)">
						<span> 提交时间：{{ formatTime(item.submitDate) }}</span>
						<span class="but">报名信息</span>
					</div>
					<div class="name" v-if="item.status == 3">
						<span> 提交时间：{{ formatTime(item.submitDate) }}</span>
						<span class="but" @click="newlyadded(item, 1)">编辑</span>
						<span class="but" @click="newlyadded(item, 3)">查看原因</span>
					</div>
				</div>
			</div>
			<div class="paging">
				<pagination :total="total" @currentchange="currentchange" :type="2" :go="10" :pageSize="12"
					:refreshNum="refreshNum" :pageNo="info.pageNo"></pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {},
	data() {
		return {
			tableData: [],
			info: {
				matchName: '',
				status: '',
				userId: '',
				pageNo: 1,
				pageSize: 10
			},
			options1: [
				{
					itemText: '未提交',
					itemValue: 0
				},
				{
					itemText: '待审核',
					itemValue: 1
				},
				{
					itemText: '审核通过',
					itemValue: 2
				},
				{
					itemText: '已退回',
					itemValue: 3
				},

			],
			total: 0,
			refreshNum: 1,
		};
	},
	computed: {

	},
	watch: {},
	methods: {
		formatTime(timeString) {
			// 确保传入的字符串是有效的
			if (!timeString) return '';
			// 使用 substring 去掉秒数，返回前16个字符
			return timeString.substring(0, 16);
		},
		currentchange(val) {
			this.info.pageNo = val
			this.userEnrollList()
		},
		searchfor() {
			this.info.pageNo = 1
			this.refreshNum = 2
			this.userEnrollList()
		},
		userEnrollList() {
			this.info.userId = this.$store.state.userid
			this.$api.userEnrollList(this.info).then(res => {
				res.data.data.result.records.forEach(item => {
					if (item.image) {
						this.$api.getFile({ objectName: item.image }).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})
				this.tableData = res.data.data.result.records
				this.total = res.data.data.result.total
			})
		},
		newlyadded(row, e) {
			if (e == 3) {
				this.$alert(row.rejectReason, '返回原因', {
					confirmButtonText: '关闭',
					callback: action => { }
				});
			} else {
				if (e == 1) {
					if (this.isCurrentTimeGreaterThanTarget(row.matchDateEnd)) {
						return this.$alert('报名时间已结束，无法编辑', '提示', {
							confirmButtonText: '关闭',
							callback: action => { }
						});
					}
				}
				this.$router.push({
					path: '/h5/registerregistrationform',
					query: {
						refresh: new Date().getTime(),
						matchId: row.matchId,
						groupName: row.enrollProject.split(",")[0],
						projectName: row.enrollProject.split(",")[1],
						disabled: e == 1 ? false : true,
						id: row.id
					},
				})
			}
		},
		getcertificate(e, title) {
			this.$alert(e, title, {
				confirmButtonText: '关闭',
				callback: action => { }
			});
		},
		isCurrentTimeGreaterThanTarget(targetTime) {
			// 获取当前时间的时间戳（单位：毫秒）
			const currentTimeStamp = new Date().getTime();
			// 使用正则表达式来解析日期时间字符串
			const match = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/.exec(targetTime);
			if (!match) {
				// 如果日期时间字符串不匹配预期的格式，返回错误或者做适当处理
				return false;
			}
			// 提取年、月、日、小时、分钟和秒
			const [, year, month, day, hour, minute, second] = match;
			// 构建目标时间的Date对象
			const targetDate = new Date(year, month - 1, day, hour, minute, second);
			// 获取目标时间的时间戳
			console.log(targetDate)
			const targetTimeStamp = targetDate.getTime();
			// 比较当前时间和目标时间
			if (currentTimeStamp > targetTimeStamp) {
				return true;
			} else if (currentTimeStamp < targetTimeStamp) {
				return false;
			}
		},
	},
	created() {
		this.userEnrollList()
	},
	mounted() {

	},
	beforeCreate() { },
	beforeMount() { },
	beforeUpdate() { },
	updated() { },
	beforeDestroy() { },
	destroyed() { },
	activated() { },
}
</script>

<style lang='scss' scoped>
.box {
	margin: 0 22PX 20PX 22PX;
	width: calc(100% - 44PX);
	height: 137PX;
	background: linear-gradient(180deg, #EFF9FF 0%, #FFFFFF 84%, #FFFFFF 100%);
	box-shadow: 0PX 15PX 54PX 0PX rgba(4, 0, 0, 0.05);
	border-radius: 10PX;
	border: 1PX solid #3E81F1;
	display: flex;

	.imgbox {
		margin: 20PX 0 0 20PX;

		.img {
			width: 90PX;
			height: 60PX;
			border-radius: 10PX;

			img {
				width: 100%;
				height: 100%;
				border-radius: 5PX;
			}
		}

		.status {
			width: 90PX;
			height: 23PX;
			background: #3E81F1;
			border-radius: 5PX;
			border: 0PX solid #000000;
			opacity: 0.61;
			margin-top: 10PX;
			color: #FFFFFF;
			text-align: center;
			line-height: 23PX;
		}
	}

	.information {
		width: calc(100% - 130PX);
		margin: 20PX 10PX;


		.title {
			height: 44PX;
			font-family: Source Han Sans CN;
			font-weight: 400;
			font-size: 13PX;
			color: #333333;
		}

		.name {
			font-family: Source Han Sans CN;
			font-weight: 400;
			font-size: 12PX;
			color: rgba(51, 51, 51, 0.75);
			width: 100%;
			display: flex;
			justify-content: space-between;

			.but {
				color: #348AFE;
			}

			.nowrap {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				span {
					color: rgba(51, 51, 51, 0.75);
				}
			}
		}

	}
}

.paging {
	margin-left: 22PX;
}

.zx-box {
	margin-top: 40PX;
	display: flex;
	flex-direction: column;
	align-items: center;

	.zw {
		width: 257PX;
		height: 162PX;
	}

	.zwxx {
		font-family: SourceHanSansCN;
		font-weight: 500;
		font-size: 15PX;
		color: #989898;
		margin-top: 27PX;
		margin-bottom: 40PX;
	}
}

.nian {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 25PX;

	img {
		width: 17PX;
		height: 17PX;
		flex-shrink: 0;
	}

	p {
		font-size: 14PX;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #333333;
		margin-left: 12PX;
		flex-shrink: 0;
	}

	.select ::v-deep .el-select .el-input .el-input__inner {
		border-color: transparent !important;
		background-color: transparent !important;
		box-shadow: none !important;
		color: #000;
	}

	.select ::v-deep .el-select .el-input,
	.select ::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	.select ::v-deep .el-select .el-input .el-select__caret.is-reverse {
		transform: rotateZ();
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #C0C4CC;
		font-size: 14PX;
		transition: transform .3s;
		transform: rotateZ(89deg);
		cursor: pointer;
		left: 12PX;
		position: relative;
	}

	.select ::v-deep input::-webkit-input-placeholder {
		color: #000;
	}

	.select ::v-deep .el-select .el-input .el-select__caret {
		color: #000;
	}

	::v-deep .el-input .el-input__inner {
		border: none !important;
		padding: 0 12PX;
		background: none;
	}

	::v-deep input::-webkit-input-placeholder {
		color: #000;
	}
}

.xian2 {
	margin: 0 15PX;
	height: 1PX;
	background: rgba(194, 194, 194, 0.2);
}

.buts {
	margin: 20PX 25PX;
	width: calc(100% - 50PX);
	height: 37PX;
	background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
	border-radius: 50PX;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16PX;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #FFFFFF;
}
</style>