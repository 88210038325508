<template>
	<div>
		<div class="inputbox">
			<img src="../../../assets/image/ID.png" alt="" style="width: 15PX;height: 15PX;">
			<p>证件类型</p>
			<span>*</span>
			<a-select option-label-prop="label"  placeholder="请选择" v-model="idtype"  style="width: 180PX;">
				<a-select-option v-for="item in idCardType" :key="item.itemValue" :value="item.itemValue" :label="item.itemText">
					<a-icon type="check" v-if="item.itemValue===idtype"/> 
					{{item.itemText}}
				</a-select-option>
			</a-select>
			<img src="../../../assets/image/more.png" style="margin-right: 12PX;z-index: 99;width: 6PX;height: 11PX;">
		</div>

		<div v-if="idtype == '01' || idtype == '06' || idtype == '07' " >
			<ldentityverification :edit="edit" :distinguish="distinguish" :idtype="idtype" @authorization="authorization" @getreturn="getreturn"  @cancellation="cancellation"></ldentityverification>
		</div>
		<div v-else>
			<ldentityverifications :edit="edit" :distinguish="distinguish" :idtype="idtype" @toexamine="toexamine" @cancellation="cancellation"></ldentityverifications>
		</div>

	</div>
</template>

<script>
import SingleImage from './component/SingleImage.vue'
import ldentityverification from './ldentityverification.vue'
import ldentityverifications from './ldentityverifications.vue'
export default {
	name: '',
	components: {
		SingleImage,
		ldentityverification,
		ldentityverifications
	},
	props: {
		edit: {
			type: Boolean,
			default: false
		},
		distinguish: {
			type: [String, Number],
			default: 0
		}
	},
	data() {
		return {
			idtype: this.$store.state.realnameornot.idtype || '01',
			idCardType: [],
		}
	},
	created() {
		this.getIdCardType()
		this.$getwxsdk('身份验证 -北京市体育竞赛管理和国际交流中心')
	},
	methods: {
		getIdCardType() {
			this.$api.idCardType({ dictValue: '其他' }).then(res => {
				this.idCardType = res.data.data.result
			})
		},
		cancellation() {
			this.$emit('cancellation')
		},
		authorization(){
			this.$emit('authorization')
		},
		getreturn(){
			this.$emit('toexamine')
		},
		toexamine(){
			this.$emit('toexamine')
		}
	}
}
</script>

<style lang="scss" scoped>
.back {
	background: #F8FAFF;
	border-radius: 27PX;
	padding: 0 0 20PX 0;
	margin: 20PX 0;
}

.tel {
	// display: flex;
	padding-top: 27PX;
	margin-left: 47PX;
}

.tel img {
	width: 17.3PX;
	height: 27.7PX;
}

.telnumber {
	font-size: 13PX;
	font-weight: 400;
	color: #333333;
	margin-bottom: 20PX;
}

.tel .asterisk {
	font-size: 25PX;
	font-weight: 400;
	color: #E6101C;
	margin-left: 5PX;
	margin-right: 59PX;
}

.tel input {
	width: 312PX;
	height: 43PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	padding-left: 10PX;
	font-size: 18PX;
	margin-left: 37PX;
}

.tel ::v-deep .el-input__inner {
	width: 312PX;
	background: #FFFFFF;
	border: 1PX solid #164B92;
	border-radius: 7PX;
	font-size: 18PX;
	color: #333333;
}

.tel ::v-deep input::-webkit-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-moz-input-placeholder {
	color: #333333;
}

.tel ::v-deep input::-ms-input-placeholder {
	color: #333333;
}

.tel ::v-deep .el-select .el-input .el-select__caret {
	color: #191919;
}

.upload-box {
	display: flex;
	flex-direction: column;
}

.upload {
	display: flex;
}

.upload ::v-deep .el-upload-dragger {
	width: 136PX;
	height: 81PX;
	border: 1PX dashed #2368E1;
}

.example {
	// display: flex;
}

.example span {
	font-size: 13PX !important;
	font-weight: 500;
	color: rgba(10, 109, 243, 0.58) !important;
	margin-left: 40PX;
	margin-top: 0 !important;
}

.example img {
	width: 122PX;
	height: 95PX;
	border-radius: 16PX;
	margin-left: 41PX;
	margin-top: 20PX;
}

.upload-box p {
	font-size: 15PX;
	font-weight: 400;
	color: #333333;
	margin-top: 40PX;

}

.line {
	background: #fff;
	height: 4PX;
	margin-top: 31PX;
}

::v-deep .el-radio__label {
	display: none;
}

::v-deep .el-radio__inner {
	width: 19PX;
	height: 19PX;
}

::v-deep .el-radio__input.is-checked .el-radio__inner {
	border-color: #0A6DF3;
	background: #0A6DF3;
}

.el-radio {
	margin-right: 5PX !important;
}

.radio {
	display: flex;
	align-items: center;
	margin: 39PX;
}

.radio .sp {
	color: #E6101C;
	margin-right: 8PX;
	font-size: 20PX;
}


.radio p {
	font-size: 12PX;
	font-weight: 400;
	color: #333333;
}

.button {
	display: flex;
	justify-content: center;

	.save {
		width: 100%;
		height: 45PX;
		background-image: linear-gradient(to bottom, #6FB2FF, #095FFF);
		font-size: 15PX;
		border-radius: 50PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin: 0 35PX 0;
	}
}


.button2 {
	display: flex;
	justify-content: center;
	margin: 30PX 0;

	.save {
		width: 100%;
		height: 45PX;
		background-image: linear-gradient(to bottom, #D8E2EB, #C2D0E6);
		font-size: 15PX;
		border-radius: 50PX;
		font-weight: bold;
		color: #FFFFFF;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin: 0 35PX 0;
	}
}



.submitt {
	width: 320.2PX;
	height: 67PX;
	background: #A2BAD5;
	margin-left: 67PX;
	font-size: 24PX;
	font-weight: bold;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.el-select-dropdown__item {
	font-size: 15PX;
}

::v-deep .el-input__icon {
	display: none;
}

::v-deep .el-input--prefix .el-input__inner {
	padding-left: 15PX;
}

.inputbox {
	display: flex;
	align-items: center;
	height: 41PX;
	margin: 24PX 35PX 0;
	background: #F1F6FF;
	border-radius: 50PX;

	img {
		width: 10PX;
		height: 16PX;
		margin-left: 16PX;
	}

	p {
		font-size: 12PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		line-height: 19PX;
		flex-shrink: 0;
		margin-left: 9PX;
	}

	span {
		font-size: 13PX;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #E90709;
		flex-shrink: 0;
		margin-left: 4PX;
	}

	::v-deep .el-input__inner {
		border: none;
		background: #F1F6FF;
		border-radius: 50PX;
	}

	.inputboxbut {
		flex-shrink: 0;
		margin-right: 11PX;
		padding: 5PX 10PX;
		font-size: 12PX;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 19PX;
		background: linear-gradient(to bottom, #60B8FF, #1F6EEE);
		border-radius: 50PX;
	}

	::v-deep .el-select .el-input,
	::v-deep .el-select-dropdown .el-select-dropdown__item.selected {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}

	::v-deep input::-webkit-input-placeholder {
		color: #333333;
	}
}
.briefintroduction{
	color: #8b8c90;
	font-size: 13PX;
	justify-content: center;
	margin: 20PX 47PX  0 47PX;
}
::v-deep .ant-select-selection--single{
	width: 180PX;
}
::v-deep .ant-select-arrow{
	display: none !important;
}
</style>